import { Inject, Injectable, OnDestroy } from '@angular/core';
import { payrollRoutes } from '@app/payrolls/payrolls-routes';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { LoggingService } from '@logging/logging.service';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { createGroupHandoverStatusPillConfig, createPayrollHandoverStatusPillConfig } from '@shared-payrolls';
import { toCurrency } from '@utils/currency-utils';
import { combineLatest, Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { EmployerStatistics, EmployerStatisticsState } from './employer-statistics';
import { EmployerStatisticsDataProvider } from './employer-statistics.data-provider';
import { getIconConfig } from './get-icon-config';
import { Summary } from '@design/components/summary-tile/summary';
import { PaymentRunStepsComponent } from '@app/payrolls/payment-runs/steps/payment-run-steps.component';
import { PayrollSummary } from '@app/payrolls/payroll-hub/payroll-statistics/state/payroll-statistics';
import { getLatestPaymentRunByPriority, getReadyForApprovalPaymentRun } from '@app/payrolls/payment-runs/payment-run-utilities';
import { formatDate } from '@utils/date-utils';

@Injectable()
export class EmployerStatisticsStateService implements OnDestroy {
  private static storeName = 'employer-payroll-stats-models-service';

  private initialValue: EmployerStatisticsState = {
    employerStatistics: null,
    inFlight: false
  };

  private readonly store = createStore(
    { name: EmployerStatisticsStateService.storeName },
    withProps<EmployerStatisticsState>(this.initialValue)
  );

  employerPayrollStats$ = this.store.pipe(
    select((q) => q.employerStatistics),
    filter((v) => !!v)
  );

  showGroups = false;

  payrollSummaries$ = combineLatest([
    this.employerPayrollStats$,
    this.featureFlagService.getValue$('allowGroupHandover').pipe(map((x) => !!x))
  ]).pipe(map(([statistics, allowGroupHandover]) => this.toPayrollSummaries(statistics, allowGroupHandover)));

  constructor(
    private dataProvider: EmployerStatisticsDataProvider,
    private activeSecurity: ActiveSecurityContextStateService,
    @Inject(FeatureFlagsServiceInjectionToken) private featureFlagService: FeatureFlagService,
    private securityStateService: ActiveSecurityContextStateService,
    private logger: LoggingService
  ) {
    this.featureFlagService.getValue$('allowGroupHandover').subscribe((showGroups) => {
      this.showGroups = !!showGroups;
    });
  }

  isLoading$ = this.store.pipe(select((state) => state.inFlight));

  fetch(employerId: number): Observable<EmployerStatistics> {
    this.store.update(setProp('inFlight', true));
    return this.dataProvider.readMany$(employerId).pipe(
      tap((employerStatistics) => {
        this.logger.trace('EmployerPayrollStatsStateService: data', employerStatistics);
        this.store.update(setProp('employerStatistics', employerStatistics));
      }),
      finalize(() => {
        this.store.update(setProp('inFlight', false));
      })
    );
  }

  private toPayrollSummaries(employerPayrollStatistics: EmployerStatistics, allowGroupHandover: boolean): Summary[] {
    const summaries: Summary[] = [];
    employerPayrollStatistics.payrollSummaries.forEach((payrollSummary) => {
      const { unsubmittedGroupCount, submittedGroupCount, isLocked: isPayrollLocked } = payrollSummary;

      let nextPayDayData;

      nextPayDayData = {
        value: formatDate(payrollSummary.usualPayDate, 'DD MMM YYYY').toString(),
        label: 'Pay day'
      };

      const statusPillConfig =
        this.activeSecurity.isServiceTypeSource(payrollSummary.id) &&
        this.activeSecurity.hasOrganisationAuthorityToOneOf(['HandoverReOpenPayroll', 'HandoverSubmitPayroll'])
          ? createPayrollHandoverStatusPillConfig({
              isPayrollLocked,
              isOssUser: this.activeSecurity.isOssUser()
            })
          : null;

      const additionalStatusPillConfig =
        allowGroupHandover && (unsubmittedGroupCount > 0 || submittedGroupCount > 0)
          ? createGroupHandoverStatusPillConfig({ unsubmittedGroupCount, submittedGroupCount })
          : null;

      const paymentRunStatus =
        this.activeSecurity.isServiceTypeSource(payrollSummary.id) &&
        this.activeSecurity.hasOrganisationAuthorityToOneOf(['HandoverReOpenPayroll', 'HandoverSubmitPayroll'])
          ? buildSummaryPayrollHandoverPill(payrollSummary)
          : null;

      summaries.push({
        iconConfig: getIconConfig({ isPayrollLocked, allowGroupHandover }),
        routeTo: '/' + payrollRoutes.payrollHub(payrollSummary.id),
        label: payrollSummary.name,
        values: new Map<string, string>([
          ['Active employees', payrollSummary.employeeCount.toString()],
          ['Latest net pay', toCurrency(payrollSummary.netPay, 2, payrollSummary.currency)],
          [nextPayDayData.label, nextPayDayData.value]
        ]),
        employerId: payrollSummary.employerId,
        employerName: payrollSummary.employerName,
        paymentRunStatus,
        statusPillConfig,
        additionalStatusPillConfig,
        unsubmittedGroupCount,
        submittedGroupCount,
        showGroupsCounts: this.showGroups
      });
    });
    return summaries;
  }

  getNextPayDay = (daysTillNextPay: number): { value: string; label: string } => {
    if (daysTillNextPay === 0)
      return {
        value: 'Today',
        label: 'Next pay day'
      };

    if (daysTillNextPay < 0)
      return {
        value: `${Math.abs(daysTillNextPay)} days`,
        label: 'Since last pay day'
      };

    return {
      value: `${daysTillNextPay || '?'} days`,
      label: 'Until next pay day'
    };
  };

  ngOnDestroy(): void {
    this.store.destroy();
  }
}

export const buildSummaryPayrollHandoverPill = (payrollSummary: PayrollSummary) => {
  const paymentRun = getLatestPaymentRunByPriority(payrollSummary.payrollPeriodRuns);

  const bureauProcessesPayrollBacs = payrollSummary.payrollPeriodRuns.bureauProcessesPayrollBacs;

  const handoverComplete = !!(payrollSummary.submittedDate ?? paymentRun?.payrollHandoverDate);
  const approvalComplete = !!paymentRun?.payrollApprovedDate;
  const authoriseComplete = !!paymentRun?.paymentAuthorisedDate;

  const awaitingApproval =
    getReadyForApprovalPaymentRun(payrollSummary.payrollPeriodRuns) !== null && payrollSummary.payrollPeriodRuns.isCurrentPeriod;

  const completeByDate = new Date(paymentRun?.authoriseByDate);
  const completeByDateString = isNaN(completeByDate.getTime()) ? undefined : new Date(completeByDate.setHours(15)).toISOString();
  if (
    payrollSummary.reopened ||
    !handoverComplete ||
    (handoverComplete && bureauProcessesPayrollBacs && awaitingApproval && !approvalComplete)
  ) {
    return PaymentRunStepsComponent.createStepPillConfig({
      step: 'handover',
      isPayrollLocked: payrollSummary.isLocked,
      stepCompleted: handoverComplete,
      actionedDate: payrollSummary.submittedDate,
      showAwaitingText:
        payrollSummary.reopened || !handoverComplete || (handoverComplete && approvalComplete && paymentRun.payrollHandoverBy == null),
      showActionedByUser: false,
      actionedByUser: paymentRun?.payrollHandoverBy,
      useDarkMode: false,
      shrinkPadding: true,
      rightAlign: true,
      fullWidth: false,
      completeByDate: completeByDateString,
      reopened: payrollSummary.reopened
    });
  } else if ((handoverComplete && !approvalComplete) || (approvalComplete && !bureauProcessesPayrollBacs)) {
    return PaymentRunStepsComponent.createStepPillConfig({
      step: 'approval',
      isPayrollLocked: payrollSummary.isLocked,
      stepCompleted: approvalComplete,
      actionedDate: paymentRun?.payrollApprovedDate,
      showAwaitingText: paymentRun?.payrollHandoverDate && !paymentRun?.payrollApprovedDate,
      showActionedByUser: false,
      actionedByUser: paymentRun?.payrollApprovedBy ?? paymentRun?.payrollHandoverBy,
      useDarkMode: false,
      shrinkPadding: true,
      rightAlign: true,
      fullWidth: false,
      completeByDate: completeByDateString,
      reopened: payrollSummary.reopened
    });
  } else {
    return PaymentRunStepsComponent.createStepPillConfig({
      step: 'authorisation',
      isPayrollLocked: payrollSummary.isLocked,
      stepCompleted: authoriseComplete,
      actionedDate: paymentRun?.paymentAuthorisedDate,
      showAwaitingText: paymentRun?.payrollApprovedDate && !paymentRun?.paymentAuthorisedDate,
      showActionedByUser: false,
      actionedByUser: paymentRun?.paymentAuthorisedBy ?? paymentRun?.payrollApprovedBy,
      useDarkMode: false,
      shrinkPadding: true,
      rightAlign: true,
      fullWidth: false,
      completeByDate: completeByDateString,
      reopened: payrollSummary.reopened
    });
  }
};
